import { callWithNuxt } from "#app";

/**
 * navigateTo() may not work in a try / catch block
 * https://github.com/nuxt/nuxt/issues/14269#issuecomment-1397352832
 */
export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();
  const { user, fetchCustomer } = useUser();

  if (!user.value) await fetchCustomer();

  if (!user.value) return callWithNuxt(nuxtApp, navigateTo, ["/login"]);
});
